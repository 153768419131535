<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />
    <div class="container default-padding">
      <div class="section-padding">
        <div class="primary-heading">{{ privacyPolicy.title }}</div>
        <div class="caption">
          {{ privacyPolicy.subtitle }}
        </div>
      </div>

      <div v-html="privacyPolicy.content"></div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";

  import BreadCrumb from "../components/BreadCrumb.vue";

  export default {
    name: "PrivacyPolicy",

    components: {
      BreadCrumb,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "Privacy Policy", path: "" }],
      };
    },

    computed: {
      ...mapState({
        allContents: (state) => state.editableContent.editableContents.data,
      }),

      privacyPolicy() {
        return (
          this.allContents &&
          this.allContents.find(
            (c) => c.title.toLowerCase() == "privacy policy"
          )
        );
      },
    },
  };
</script>

<style lang="scss" scoped></style>
